.Stories-container{
    background-color: rgb(3, 3, 64);
    padding: 10px;
  
}

.stories-img{
width:100%;
height:30%;
}

.ul-container{
  justify-content: space-between;
 display: flex;

margin-left: 10px;
margin-right: 100px;
margin-bottom: ;
}
.item-container{
    
    border-color: black;
    border-width: 1px;
    border-style: solid;
   background-color: darkblue;
    width:30%;
    border-top-left-radius:10px ;
    border-top-right-radius:10px ;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius:10px ;

}
.title-paragraph{
    margin-bottom: -20px;
padding: 10px;
color: white;
}

.refund{
    padding: 10px;
    margin-top: -20px;
    font-size: large;
    font-weight: bold;
    color: yellow;
}
.subtitle{
    padding: 10px;
    font-size:medium;
    margin-top: -30px;
    color: white;
}
.description{
    padding: 10px;
    margin-top: -30px;
    color: white;
   
}
