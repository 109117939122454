.Askedquestions-container {
    display: flex;
    width: 100%;
    position: absolute;
    background-color: darkblue;
    height: 100%;
    margin-top: 350px;
 
    
}

.leftside {
    width: 30%;

height:100%;
    display: flex;
    flex-direction: column;
    margin-top: 120px;

    background-color: darkblue;
    
}

.right {
    width: 70%;
    height: 100%;
    background-color: darkblue;
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    position: relative;
    padding: 20px;
}

.question {

   display: flex;
   align-items: center;
   margin-bottom: 10px;
   
}

.question1 {
    display: flex;
    flex-direction: row;
    border-color:black ;
    border-width:1px ;
    border-style: solid;
    margin-bottom: 20px;
    background-color: rgb(3, 3, 64);
    color: white;
   padding-left: 30px;
   width:70%;
   margin-left: 60px;
}

.question2 {
    border-color:black ;
    border-width:1px ;
    border-style: solid;
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    background-color: rgb(3, 3, 64);
    color: white;
    padding-left: 30px;
    width:70%;
    margin-left: 60px;
}

.question3 {
    border-color:black ;
    border-width:1px ;
    border-style: solid;
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    background-color: rgb(3, 3, 64);
    color: white;
    padding-left: 30px;
    width:70%;
    margin-left: 60px;
}

.question4 {
    border-color:black ;
    border-width:1px ;
    border-style: solid;
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    background-color: rgb(3, 3, 64);
    color: white;
    padding-left: 30px;
    width:70%;
    margin-left: 60px;
}

.question5 {
    border-color:black ;
    border-width:1px ;
    border-style: solid;
    display: flex;
    flex-direction: row;
    background-color: rgb(3, 3, 64);
    color: white;
    padding-left: 30px;
    width:70%;
    margin-left: 60px;
    
}
.button{
    border: none;           /* Removes the border */
    outline: none;          /* Removes the outline when the button is focused */
    box-shadow: none;       /* Removes any box shadow applied to the button */
    background: transparent;/* Optional: Makes the button background transparent */
    font-size: 30px;
    margin-left: 500px;
    position: absolute;
    color: yellow;
}