.card-item{
    
    border-color: black ;
    border-width: 2;
    border-style: solid;
    width:26%;
    justify-content: space-between;
    height:17%;
    background-color: darkblue;
    border-top-left-radius: 10% ;
    border-top-right-radius: 10%;
    border-bottom-left-radius: 10%;
    border-bottom-right-radius:10% ;
}
.Card-Title{
    background-color: rgb(3, 3, 64);
   padding-top: 5px;
   padding-bottom: 20px;
}
.Card-container{
    display: flex;
    width: 85%;
    

   gap:30px;
    margin-left: 10%;
}

.logo{
    margin-left: 45%;
    padding-top: 10%;
    color: yellow;

}