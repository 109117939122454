
  .headertitle1{
    font-size: 40px;
    color: white;
    margin-left: -400px;
    margin-top: -100px;
    position: absolute;
   
  }
  
  .headertitle2{
    font-size: 40px;
    color: yellow !important;
    margin-left: -400px;
    margin-top: -50px;
   position: absolute;
  }

  .headertitle3{
    font-size: 40px;
    color:white;
    margin-left: -400px;
    margin-top: 0px;
   position: absolute;
  }
  .background-container {
    background-image: url('../images/fallingonstairs.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 80vh;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; /* Center text horizontally */
    color: white; /* Text color on top of the background */
  }
  .background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent overlay */
  }
  .content {
    z-index: 1; /* Ensure content is above the background */
    position: absolute;
    margin-left: -250px;
}
  
  .content h1,
  .content p {
    color: white; /* Adjust text color */
  }