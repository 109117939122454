.line-container {
    display: flex;
    align-items: center;
  margin-left: 0px;
    width:100px;
    font-size: xx-small;
    margin-top: 30px;
    color: white;
  }
  .span{

    margin-left: -7px;
    margin-right: -7px;
    color: white;
    position: absolute;
  }
  .line-container::before,
  .line-container::after {
    content: '';
    flex: 1;
 
    border-bottom: 1px solid white; /* White line */
  }
  
  .line-container::before {
    margin-right: 10px;
  }
  
  .line-container::after {
    margin-left: 10px;
  }
  .shlivko{
    margin-bottom: 40px;
    position: absolute;
    font-size:20px;
    margin-left: 9px;
    color: white;
   
    
  }
  .young{
    margin-top: 40px;
    position: absolute;
    font-size: 20px;
    margin-left: 15px;
    color: white;
  }
  .line-container span {
    color: white; /* White & symbol */
  }