.nav{
    background-color: darkblue;
    height:15%;
    position: absolute;
    margin-top: -25px;
    width:100%;  
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: -30px;
    z-index: 2;
 
   
}

  .nav ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
 
  }
  .nav li {
    margin: 0 10px; /* Adjust the spacing between links */
    margin-left: 20px;
    margin-right: 30px;
  }
  
  .nav a {
    color: white;
    text-decoration: none;

  
    
  }
  
  .nav a:hover {
    text-decoration: underline;
  }